function searchEngine(query, record) { return record;}

export default function initAutocompleteBooks(url, selector, selectionFn, searchBooks, searchOlWorks) {
  let noMatchesTimeout = null;

  const config = {
    searchEngine: searchEngine,
    placeHolder: "Search for books...",
    data: {
      src: async (query) => {
        try {
          if (noMatchesTimeout) {
            clearTimeout(noMatchesTimeout);
            noMatchesTimeout = null;
          }

          if (query.length < 2) {
            const noMatchesSection = document.querySelector('#noMatchesSection');
            if (noMatchesSection) {
              noMatchesSection.classList.add('d-none');
            }
            return [];
          }

          let filteredData = [];
          const queryRegex = new RegExp(query, 'i');

          if (searchBooks) {
            const source = await fetch(`${url}?query=${query}`);
            let data = await source.json();
            filteredData = data.filter(item => {
              return item;
            });
          }

          if (filteredData.length > 0) {
            const noMatchesSection = document.querySelector('#noMatchesSection');
            if (noMatchesSection) {
              noMatchesSection.classList.add('d-none');
            }
            return filteredData;
          }

          if (filteredData.length === 0 && searchOlWorks) {
            const source = await fetch(`${url}?query=${query}&index=ol_works`);
            let data = await source.json();
            filteredData = data.filter(item => {
              return queryRegex.test(item.title);
            });
          }

          if (filteredData.length === 0 && config.noResults) {
            noMatchesTimeout = setTimeout(() => config.noResults(), 500);
          } else {
            const noMatchesSection = document.querySelector('#noMatchesSection');
            if (noMatchesSection) {
              noMatchesSection.classList.add('d-none');
            }
          }

          return filteredData;
        } catch (error) {
          console.error("error: ", error);
          if (config.noResults) {
            noMatchesTimeout = setTimeout(() => config.noResults(), 500);
          }
          return error;
        }
      },
      // Data 'Object' key to be searched
      keys: ["display_text"],
      filter: (list) => {
        console.log("filter list: ", list)
        const uniqueMatches = {};

        // Filter the list to only include unique matches.
        const filteredList = list.filter(item => {
          // Convert the match value to lowercase (or uppercase) for case-insensitive comparison.
          const matchValue = item.match.toLowerCase();
        
          // Check if the lowercase match value of the current item is not already in uniqueMatches.
          if (!uniqueMatches[matchValue]) {
            // If it's not, add it to uniqueMatches and keep this item in the filtered list.
            uniqueMatches[matchValue] = true;
            return true;
          }
          // If it's already in uniqueMatches, filter out this item.
          return false;
        });        

        return filteredList;
      }
    },
    resultsList: {
      tag: "ul",
      class: "list-group autocomplete-book-results",
    },
    resultItem: {
      tag: "li",
      class: "list-group-item list-group-item-secondary autocomplete-results-item small-text"
    },
    selector: selector,
    events: {
      input: {
          selection: selectionFn
      }
    },
    noResults: () => {
      const noMatchesSection = document.querySelector('#noMatchesSection');
      if (noMatchesSection) {
        noMatchesSection.classList.remove('d-none');
      }
    },
    onSubmit: () => {
      if (noMatchesTimeout) {
        clearTimeout(noMatchesTimeout);
        noMatchesTimeout = null;
      }
      const noMatchesSection = document.querySelector('#noMatchesSection');
      if (noMatchesSection) {
        noMatchesSection.classList.add('d-none');
      }
    }
  };
  return config;
}

